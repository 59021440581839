import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import favicon from '../../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";


// images
import banner_img from "../../images/icons/ta-emot.svg";
import e_faktura from '../../images/undersidor/e-faktura.svg';
import pdf_faktura from '../../images/undersidor/pdf_faktura.svg';
import skanning from '../../images/undersidor/skanner.svg';
import fakturadata from '../../images/undersidor/skanningsprogramvara.svg';

const BOT_CODE = "kxFV5wCG";

function Leverantorsfakturor() {
    const [chatbot_1, setChatbot_1] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>Supplier Invoices | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="With CrossState eVoyce you get digital and efficient invoice management" />
                <meta name="keywords" content="Invoice management | CrossState eVoyce" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="With CrossState eVoyce you get digital and efficient invoice management" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="en_EN" />
                <meta property="og:url" content="https://www.crediflow.com/supplier-invoices" />
                <link rel="cannonical" href="https://www.crediflow.com/supplier-invoices" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">Digital and efficient invoice management</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        With CrossState eVoyce, you get perhaps the market's 
                                        most flexible service for managing your invoices. 
                                        One channel in regardless of format, e-invoice, 
                                        PDF and paper. A self-learning system that builds 
                                        knowledge as you use it with header or row level 
                                        interpretation. You choose for yourself whether 
                                        you want to outsource handling such as scanning 
                                        and verification or handle it yourself.
                                    </p>
                                    <button onClick={() => setChatbot_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Get in contact</button>
                                    {chatbot_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={BOT_CODE} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-1/2 sm:1/3 p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="service och support optosweden" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>




            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="pb-10 flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Home</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">supplier-invoices</span>
                    </div>
                    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
                        <div className="bg-white rounded-md shadow-md hover:shadow-xl transition-all transform hover:scale-105 lg:hover:-mt-2 lg:hover:mb-2">
                            <Link to="/supplier-invoices/e-invoice">
                                <div className="h-full flex flex-col justify-between">
                                    <div className="w-full px-6 pt-8 ">
                                        <div className="w-5/12 mx-auto">
                                            <img className="w-full" src={e_faktura} alt="receive e-invoices"/>
                                        </div>
                                        <h2 className="mt-6 text-lg font-semibold text-center text-gray-900 hover:text-blue-custome">E-invoice</h2>
                                        <p className="mt-4 text-gray-500">
                                            With our service, you can easily receive e-invoices in any format.
                                            We take care of the advanced behind so that you
                                            can focus on your business.
                                        </p>
                                    </div>
                                    <div className="pt-6 pb-8 flex justify-center">
                                        <button className="py-1 px-4 bg-gray-400 rounded-full text-white text-sm hover:bg-gray-500 shadow-md">Read more</button>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="bg-white rounded-md shadow-md hover:shadow-xl transition-all transform hover:scale-105 lg:hover:-mt-2 lg:hover:mb-2">
                            <Link to="/supplier-invoices/pdf-invoices"  >
                                <div className="h-full flex flex-col justify-between">
                                    <div className="w-full px-6 pt-8 ">
                                        <div className="w-5/12 mx-auto">
                                            <img className="w-full" src={pdf_faktura} alt="automation of PDF invoices"/>
                                        </div>
                                        <h2 className="mt-6 text-lg font-semibold text-center text-gray-900 hover:text-blue-custome">PDF-invoices</h2>
                                        <p className="mt-4 text-gray-500">
                                            Your invoices are analyzed by AI technology that finds
                                            and extracts data at the header or row level.
                                            Automatic matching of your suppliers and
                                            advanced knowledge training gives you increased
                                            automation and security.
                                        </p>
                                    </div>
                                    <div className="pt-6 pb-8 flex justify-center">
                                        <button className="py-1 px-4 bg-gray-400 rounded-full text-white text-sm hover:bg-gray-500 shadow-md">Read more</button>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="bg-white rounded-md shadow-md hover:shadow-xl transition-all transform hover:scale-105 lg:hover:-mt-2 lg:hover:mb-2">
                            <Link to="/supplier-invoices/scanning">
                                <div className="h-full flex flex-col justify-between">
                                    <div className="w-full px-6 pt-8 ">
                                        <div className="w-5/12 mx-auto">
                                            <img className="w-full" src={skanning} alt="scan paper invoices"/>
                                        </div>
                                        <h2 className="mt-6 text-lg font-semibold text-center text-gray-900 hover:text-blue-custome">Scanning of paper invoices</h2>
                                        <p className="mt-4 text-gray-500">
                                            Do you want to receive and scan your paper invoices yourself or do you want them scanned?
                                            <br/><br/>With us, you choose yourself.
                                        </p>
                                    </div>
                                    <div className="pt-6 pb-8 flex justify-center">
                                        <button className="py-1 px-4 bg-gray-400 rounded-full text-white text-sm hover:bg-gray-500 shadow-md">Read more</button>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="bg-white rounded-md shadow-md hover:shadow-xl transition-all transform hover:scale-105 lg:hover:-mt-2 lg:hover:mb-2">
                            <Link to="/supplier-invoices/invoice-control">
                                <div className="h-full flex flex-col justify-between">
                                    <div className="w-full px-6 pt-8 ">
                                        <div className="w-5/12 mx-auto">
                                            <img className="w-full" src={fakturadata} alt="Control of invoice data"/>
                                        </div>
                                        <h2 className="mt-6 text-lg font-semibold text-center text-gray-900 hover:text-blue-custome">Control of invoice data</h2>
                                        <p className="mt-4 text-gray-500">
                                            Read information from an invoice must always be checked, so that it is verified and
                                            correct data you then manage in your certificate or business system.
                                            <br/><br/>You choose whether you do the check yourself or whether we will do it for you?
                                        </p>
                                    </div>
                                    <div className="pt-6 pb-8 flex justify-center">
                                        <button className="py-1 px-4 bg-gray-400 rounded-full text-white text-sm hover:bg-gray-500 shadow-md">Read more</button>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>




            <section className="bg-gray-100 pt-32 pb-28">
                <div className="container mx-auto px-4 xl:px-24">
                    <hr className="text-gray-200"/>
                </div>
            </section>




            <section className="bg-gray-100 pb-32">
                <div className="container mx-auto px-4 xl:px-24">
                    <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Still not sure how it works?</h1>
                    <p className="mt-4 text-center text-gray-600">Watch our video that simply describes how our solution works!</p>
                    <div className="w-full lg:w-2/3 mx-auto mt-14">
                        <iframe 
                            style={{minHeight: "450px"}}
                            className="w-full rounded-lg shadow-lg"
                            title="youtube-video-webinar"  
                            src="https://www.youtube-nocookie.com/embed/wq00RJLYPYA"
                            frameBorder="0" 
                            allowFullScreen
                        />
                    </div>
                </div>
            </section>


        </Layout>
    );
}
export default Leverantorsfakturor;